import {
  TextInput,
  Select,
  Button,
  Radio,
  MultiSelect,
  Textarea,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import colors from "../../utilities/design";
import {
  existsAndLength,
  getValueForInput,
  loadingStates,
  validateEmail,
  validateMobile,
} from "../../utilities/utilities";
import styles from "../ServiceProviderForm/ServiceProviderForm.module.css";

import { createServiceProvider } from "../../utilities/apis/serviceProvider";
import { selectUserData } from "../../redux/selectors";
import { createBusinessUnit } from "../../utilities/apis/serviceProvider";

function AddOrganisationForm({ formClose }) {
  const currentUser = useSelector(selectUserData) || {};
  const [OrgData, setOrgData] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    businessunitName: "",
    errors: {},
    buData: {
      signName: "",
      signEmail: "",
      poc: "",
      copAddress: "",
    },
  });

  // console.log("currrr user", currentUser);

  // const userDetails = useSelector(selectUserData);
  const changeHandler = (name) => (input) => {
    const value = getValueForInput(input);
    setOrgData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const validate = () => {
    const keys = {};

    if (!existsAndLength(OrgData.businessunitName)) {
      keys.businessunitName = "Please check Business Unit Name.";
    }

    // if (!existsAndLength(OrgData.buData.entityType)) {
    //   keys.entityType = "Please check Entity Type.";
    // }
    // if (!existsAndLength(OrgData.buData.orgName)) {
    //   keys.orgName = "Please check organization name.";
    // }
    if (!existsAndLength(OrgData.buData.poc)) {
      keys.poc = "Please check Business POC.";
    }
    // if (!existsAndLength(OrgData.buData.regAddress)) {
    //   keys.regAddress = "Please check Registered Address.";
    // }
    if (!existsAndLength(OrgData.buData.signName)) {
      keys.signName = "Please check Authorised Signatory Name.";
    }
    if (!existsAndLength(OrgData.buData.signEmail)) {
      keys.signEmail = "Please check Authorised Signatory Email.";
    }

    if (Object.keys(keys).length) {
      showNotification({
        color: "red",
        title: "Service Record",
        message: "Make sure all fields are filled properly.",
      });
      setOrgData((data) => ({
        ...data,
        errors: keys,
      }));
      return false;
    }
    return true;
  };

  const OrgHandler = async () => {
    if (validate()) {
      setOrgData((data) => ({
        ...data,
        loading: loadingStates.LOADING,
        errors: {},
      }));
      const OrgApiData = {
        orgId: currentUser.mapOrganization,
        businessunitName: OrgData.businessunitName,
        buData: OrgData.buData,
      };

      console.log("---BC---", OrgApiData);

      const resp = await apiWrapWithErrorWithData(
        createBusinessUnit({
          OrgData: OrgApiData,
        })
      );
      // console.log(resp);
      if (resp?.success) {
        setOrgData((prevState) => ({
          ...prevState,
          loading: loadingStates.NO_ACTIVE_REQUEST,
        }));
        showNotification({
          color: "green",
          title: "Service Provider Record Created.",
          message: "Service Provider has been created.",
        });
        if (formClose) {
          formClose();
          window.location.reload();
        }
      } else {
        showNotification({
          color: "red",
          title: "Service Provider Record",
          message:
            resp?.message || "Service Provider Record could not be created.",
        });
        setOrgData((prevState) => ({
          ...prevState,
          loading: loadingStates.NO_ACTIVE_REQUEST,
        }));
      }
    }
  };

  console.log(OrgData);

  return (
    <div className="flex flex-col px-4 pb-8">
      <div className={styles.title}>Add Business Unit/Region</div>

      <div className="grid grid-cols-2 gap-4  mt-8">

        <TextInput
        required
          placeholder="Business Unit"
          label="Business Unit"
          value={OrgData.businessunitName}
          onChange={changeHandler("businessunitName")}
          error={OrgData.errors.businessunitName}
        />
        <TextInput
        required
          placeholder="Authorised Signatory Name"
          label="Authorised Signatory Name"
          value={OrgData.buData.signName}
          onChange={(e)=>{
            setOrgData((prevState)=>({
              ...prevState, 
              buData: {
                ...prevState.buData,
                signName: e.target.value,
              }
            }));
          }}
          error={OrgData.errors.signName}
        />

        <TextInput
        required
          placeholder="Authorised Signatory Email"
          label="Authorised Signatory Email"
          type="email"
          value={OrgData.buData.signEmail}
          onChange={(e)=>{
            setOrgData((prevState)=>({
              ...prevState, 
              buData: {
                ...prevState.buData,
                signEmail: e.target.value,
              }
            }));
          }}
          error={OrgData.errors.signEmail}
        />
        <TextInput
        required
          placeholder="Business POC"
          label="Business POC"
          value={OrgData.buData.poc}
          onChange={(e)=>{
            setOrgData((prevState)=>({
              ...prevState, 
              buData: {
                ...prevState.buData,
                poc: e.target.value,
              }
            }));
          }}
          error={OrgData.errors.poc}
        />

        <Textarea
          placeholder="Corporate Address"
          label="Corporate Address"
          rows={2}
          value={OrgData.buData.copAddress}
          onChange={(e)=>{
            setOrgData((prevState)=>({
              ...prevState, 
              buData: {
                ...prevState.buData,
                copAddress: e.target.value,
              }
            }));
          }}
        />
      </div>

      <div className="flex justify-end mt-3">
        <Button
          onClick={OrgHandler}
          disabled={OrgData.loading === loadingStates.LOADING}
          style={{
            backgroundColor: "#46BDE1",
            borderRadius: "0.5rem",
            color: "#F5F5F5",
          }}
        >
          {OrgData.loading === loadingStates.LOADING && (
            <BeatLoader color={colors.primary} size={10} />
          )}
          {OrgData.loading === loadingStates.NO_ACTIVE_REQUEST && (
            <span> Save</span>
          )}
        </Button>
      </div>
    </div>
  );
}

export default AddOrganisationForm;
